import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HomeExtraInformation = () => {
    const Other = [
        { name: "Learn About Cows", link: "/cattle-blog/common-cow-breeds-at-bps-gaushala" },
        { name: "Learn About Diary Blog", link: "/cattle-blog/benefits-of-indian-a2-milk-(desi)" },
        { name: "See Full Blog", link: "/cattle-blog" },
    ];
    return (
        <Wrapper>
            <Location className="p-4">
                <i className="fa fa-map-marker" />
                <h6 className="text-white w-75">Kapro Hisar, Haryana Hisar</h6>
            </Location>
            <CategoryWrapper className="py-4 pr-4">
                <Link to="/cattle-blog/benefits-of-indian-a2-milk-(desi)">
                    <div className="category">
                        <img loading="lazy" alt="image" width={40} src="/img/icon/dairy.png" />
                        Dairy Products
                    </div>
                </Link>
                <Link to="/cattle-blog">
                    <div className="category">
                        <img loading="lazy" alt="image" width={40} src="/img/icon/cow-genomics.png" />
                        Cattle Blog
                    </div>
                </Link>
                <Link to="/cattle-blog/common-cow-breeds-at-bps-gaushala">
                    <div className="category">
                        <img loading="lazy" alt="image" width={40} src="/img/icon/cow-cattle.png" />
                        Cow Genomics
                    </div>
                </Link>
            </CategoryWrapper>
            <OtherPets className="p-4">
                <div className="dropdown">
                    <button id="dropdownMenuButton" data-toggle="dropdown">
                        Other
                    </button>
                    <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                        {Other.map(({ name, link }) => {
                            return (
                                <button key={name}>
                                    <Link to={link}>{name}</Link>
                                </button>
                            );
                        })}
                    </div>
                </div>
            </OtherPets>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    background: #073a43; /* Adjusted based on the image */
    border-radius: 15px;
    font-family: "Nunito", sans-serif;
    margin: auto;
    margin-top: 20px;
    overflow: visible;
    position: relative;
    width: min(90%, 1200px);

    @media screen and (min-width: 700px) and (max-width: 1000px) {
        flex-wrap: wrap;
    }
    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
`;

const Location = styled.div`
    width: 30%;
    display: inline-flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    order: 1;

    i {
        font-size: 20px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
    }

    @media screen and (min-width: 700px) and (max-width: 1000px) {
        width: 50%;
    }
`;

const CategoryWrapper = styled.div`
    background: #f65008; /* Orange background */
    width: 60%; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    order: 2;

    &::before {
        content: "";
        position: absolute;
        left: -58px;
        top: 0;
        width: 60px;
        height: 100%;
        background: var(--main-color);
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 100% 50%, 0% 0%);
    }

    .category {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 16px;
        font-weight: 900;

        @media screen and (max-width: 700px) {
            flex-direction: row;
            gap: 10px;
        }

        i {
            font-size: 30px;
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 20px;
        width: 100%;
        overflow: hidden;
    }
    @media screen and (min-width: 700px) and (max-width: 1000px) {
        width: 100%;
    }
`;

const OtherPets = styled.div`
    background: #073a43; /* Match main background */
    padding: 20px;
    width: 20%; /* Adjust as needed */
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 3;
    border-radius: 10px;

    .dropdown {
        position: relative;
        .dropdown-menu {
            width: 300%;
            font-size: 14px;
            padding-inline: 10px;
        }

        button {
            background: transparent;
            border: none;
            color: #fff;
            text-align: left;
            font-weight: 900;
            cursor: pointer;

            &:hover > * {
                color: var(--main-color);
            }

            &::after {
                content: "\\f107"; /* FontAwesome down arrow */
                font-family: "Font Awesome 5 Free";
                font-weight: 700;
                margin-left: 8px;
            }
        }
    }
    @media screen and (max-width: 700px) {
        width: 100%;
    }

    @media screen and (min-width: 700px) and (max-width: 1000px) {
        order: 1;
        width: 50%;
    }
`;

export default HomeExtraInformation;
