import React, { useEffect } from "react";
import "magnific-popup";
import $ from "jquery";
import useTranslationMod from "../hooks/useTranslationMod";
import { Link } from "react-router-dom";

function Faq({ afterElment }) {
    const { t } = useTranslationMod();

const oureRescueProcessText = "BPGS has a fast and effective rescue process. As soon when we receive information about an incident (or case) our volunteers spring into action immediately. Depending on the exact circumstances and situation, and severity of the stress, our selfless volunteers ensure a rescue within half an hour. बीपीएसजी में एक तेज और प्रभावी रेस्क्यू प्रक्रिया है। जैसे ही किसी घटना या मामले की जानकारी मिलती है, हमारे स्वयंसेवक तुरंत हरकत में आ जाते हैं। स्थिति और तनाव की गंभीरता के अनुसार, हमारे निःस्वार्थ स्वयंसेवक यह सुनिश्चित करते हैं कि आधे घंटे के भीतर बचाव कार्य पूरा हो जाए।"

    useEffect(() => {
        /* magnificPopup video view */
        $(".popup-video").magnificPopup({
            type: "iframe",
        });
    }, []);

    return (
        <section className={afterElment + " faq-bg"}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="faq-img-wrap">
                            <img loading="lazy" src="/img/images/faq_tv.png" className="img-frame" alt="faq" />
                            <img loading="lazy" src="/img/other/tv-inside.jpeg" className="main-img" alt="tv inside" />
                            <Link to="https://www.youtube.com/watch?v=FeMMsUeUVds" className="popup-video"></Link>
                        </div>
                        <div className="faq-img-wrap mt-4">
                            <img loading="lazy" src="/img/images/faq_tv.png" className="img-frame" alt="faq" />
                            <img loading="lazy" src="/img/other/tv-inside2.jpg" className="main-img" alt="main" />
                            <Link to="https://www.youtube.com/watch?v=B8yIX7x7lm0" className="popup-video"></Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-wrapper">
                            <div className="section-title mb-35">
                                <h5 className="sub-title">Common Questions (FAQs)</h5>
                                <h2 className="title">{t("peoples_gaushala")}</h2>
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What is BPS Gaushala?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">{t("a_protective")} </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Importance of Cow in Indian Culture
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body">{t("in_hindiusm")}</div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What is the Cow Protection Movement?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">{t("a_popular")}</div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                               Our Rescue Process
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">{oureRescueProcessText}</div>
                                    </div>
                                </div>
                               
                                <div className="card">
                                    <div className="card-header" >
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                               Facilities at BPSG
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                        <div className="card-body">{t("facilites:bpsg_built_over")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-shape">
                <img loading="lazy" src="/img/other/cows-icons/cow-head.png" alt="head" />
            </div>
            <div className="faq-shape" style={{ left: "2%", right: "unset", bottom: "-20px" }}>
                <img loading="lazy" src="/img/other/cows-icons/cow6.png" alt="cow" />
            </div>
        </section>
    );
}

export default Faq;
