import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import parser from "html-react-parser";
import useSingleBlog from "../../hooks/useSingleBlog";
import Loader from "../../common-component/Loader";
import ErrorMessage from "../../common-component/Error";
import styled from "styled-components";

function BlogPost() {
    const { blogId } = useParams();
    const { blog, loading, error } = useSingleBlog(blogId);

    if (loading) {
        return (
            <div className="p-5 m-5">
                <Loader size={50} message="Fetching Blog..." />
            </div>
        );
    }
    if (error) return <ErrorMessage message={error.message} />;

    return (
        <section className="blog-details-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="standard-blog-item mb-50">
                            {/* Blog ThumbNail */}
                            {/* <div className="standard-blog-thumb">
                                <img loading="lazy" src={blog.thumbnail_img} alt="" />
                            </div> */}
                            {/* Blog ThumbNail */}

                            {/* Blog Conatiner Start */}
                            <div className="standard-blog-content blog-details-content mt-10">
                                {/* Author Data */}
                                <div className="blog-post-meta">
                                    <ul>
                                        <li>
                                            <i className="far fa-user" />
                                            <a href="/#">{blog.author_name}</a>
                                        </li>
                                        <li>
                                            <i className="far fa-bell" /> {DateTime.fromISO(blog.createdAt).toFormat("MMMM dd ,yyyy (cccc)")}
                                        </li>
                                    </ul>
                                </div>
                                {/* Author Data */}

                                <h4 className="title mt-10">{blog.title}</h4>

                                <BlogContainer className="main-content mt-30">{parser(blog.content)}</BlogContainer>

                                <div className="blog-details-bottom">
                                    <div className="blog-details-tags">
                                        <ul>
                                            <li className="title">
                                                <i className="fas fa-tags" /> Tags : {blog.tags?.length ? blog.tags.join(", ") : "No Tags"}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="blog-details-social">
                                        <ul>
                                            <li>
                                                <a href="/#">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Blog Conatiner End */}
                        </div>
                        {/* <div className="avatar-post mt-50 mb-50">
                            <div className="post-avatar-img">
                                <img loading="lazy" src="/img/blog/post_avatar_img.png" alt="img" />
                            </div>
                            <div className="post-avatar-content">
                                <h5>Thomas Herlihy</h5>
                                <p>Non-core vaccines are given dependng on the cow's exposure risk. These include vaccines against Bordetella.</p>
                                <div className="blog-details-social">
                                    <ul>
                                        <li>
                                            <a href="/#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="blog-next-prev">
                            <ul>
                                <li className="blog-prev">
                                    <a href="/#">
                                        <img loading="lazy" src="/img/icon/left_arrow.png" alt="img" />
                                        Previous Post
                                    </a>
                                </li>
                                <li className="blog-next">
                                    <a href="/#">
                                        Next Post
                                        <img loading="lazy" src="/img/icon/right_arrow.png" alt="img" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="comment-reply-box">
                            <h5 className="title">Leave a Reply</h5>
                            <form className="comment-reply-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-grp">
                                            <input type="text" placeholder="Author *" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-grp">
                                            <input type="email" placeholder="Your Email *" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-grp">
                                    <textarea name="message" placeholder="Type Comment Here..." defaultValue={""} />
                                </div>
                                <div className="form-grp checkbox-grp">
                                    <input type="checkbox" id="checkbox" />
                                    <label htmlFor="checkbox">Don’t show your email address</label>
                                </div>
                                <button type="submit" className="btn">
                                    Submit now
                                </button>
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
}

const BlogContainer = styled.div`
    figcaption {
        font-size: 14px;
        padding: 4px 0px;
    }

    ul {
        list-style-type: disc !important; /* Enable bullets */
        padding-left: 20px !important; /* Add padding for indentation */
        margin-left: 20px !important;
    }

    ul ul {
        list-style-type: circle !important; /* Nested lists use circles */
        padding-left: 20px !important;
    }

    li {
        margin-bottom: 5px; /* Add spacing between list items */
        list-style: disc;
    }
`;

export default BlogPost;
