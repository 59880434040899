import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import  useTranslationMod  from "../hooks/useTranslationMod";

function Adoption() {
    const { t } =  useTranslationMod();
    return (
        <section className="adoption-area">
            <div className="container ">
                <div className="row align-items-center  justify-content-center">
                    <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
                        <div className="adoption-img text-center">
                            <img loading="lazy" src="/img/other/payment.png" width={340} alt="payment.jpg" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="adoption-content">
                            <h2 className="title">{t("support_donations")}</h2>
                            <p>{t("make_a_donation")}</p>
                            <Link to="/adoption">
                                <ExpandButton className="btn">
                                    Instant Rescue <img loading="lazy" src="/img/icon/milksplash-w.png" alt="splash" /> <span className="number pl-2">9896784225 </span>
                                </ExpandButton>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="adoption-shape"><img loading="lazy" src="img/images/adoption_shape.png" alt="" /></div> */}
        </section>
    );
}

const ExpandButton = styled.button`
    .number {
        max-width: 0px;
        opacity: 0;
        transition: max-width 1s ease-out;
    }

    &:hover .number {
        opacity: 1;
        max-width: 200px;
    }
`;

export default Adoption;
