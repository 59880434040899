import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import $ from "jquery";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid } from "swiper/modules";
import "swiper/css";
import "swiper/css/grid";
import Loader from "../common-component/Loader";
import axios from "axios";
import { Link } from "react-router-dom";

function Header() {
    const [searchQuery, setSearchQuery] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    let debounceFetch = useRef(null);

    useEffect(() => {
        const fetchSearchResults = async () => {
            setSearchLoading(true);
            axios
                .get("/blog/search?q=" + searchQuery)
                .then((res) => setSearchResults(res.data))
                .finally(() => setSearchLoading(false));
        };

        if (!!debounceFetch.current) {
            clearTimeout(debounceFetch.current);
        }

        if (searchQuery) {
            debounceFetch.current = setTimeout(() => {
                fetchSearchResults();
            }, 1000);
        }
    }, [searchQuery]);

    const header = useRef();

    useGSAP(
        () => {
            const timeline = gsap.timeline();
            timeline
                .from(".navigation li:not(li li)", {
                    opacity: 0,
                    y: 20,
                    stagger: 0.2,
                })
                .from(".header-action li", {
                    opacity: 0,
                    x: 20,
                    stagger: 0.1,
                });
        },
        { scope: header }
    );

    const MENU_ITEMS = [
        {
            name: "Home",
            link: "/",
        },
        {
            name: "BPSG Essentials",
            subMenu: [
                { name: "About BPSG", link: "/about" },
                { name: "Chairman's Message", link: "/message-from-chairman" },
                { name: "President's Message", link: "/message-from-president" },
                { name: "Spread Happiness", link: "/spread-happiness" },
                { name: "Production", link: "/production" },
                { name: "Events & Updates", link: "/events-updates" },
                { name: "HGSA Support", link: "/hgsa-support" },
            ],
        },
        {
            name: "BPSG Operations",
            subMenu: [
                { name: "Our Facilities", link: "/our_facilities" },
                { name: "Food Provision", link: "/food_provision" },
                { name: "Rescue Process", link: "/rescue_process" },
                { name: "Medical Treatment", link: "/medical-treatment" },
                { name: "Breed improvements", link: "/cow-genetics" },
                { name: "Photo Gallery", link: "/photo-gallery" },
                { name: "Video Vault", link: "/video-vault" },
            ],
        },
        { name: "Photo Gallery ", link: "/photo-gallery" },
        { name: "Cattle Blog", link: "/cattle-blog" },
        { name: "Patrons", link: "/patrons" },
        { name: "Contact", link: "/contact" },
    ];

    const handleActive = (e) => {
        document.querySelectorAll(".main-menu ul li").forEach((el) => {
            el.classList.remove("active");
        });
        e.target.parentNode.classList += " active";
    };

    const closeMob = () => {
        document.querySelector(".close-btn").click();
    };

    const subActive = (e) => {
        e.currentTarget.nextElementSibling.classList.toggle("active-mob");
    };

    const closeSearch = () => {
        $(".search-popup-wrap").slideUp(500);
    };

    useEffect(() => {
        //SubMenu Dropdown Toggle
        if ($(".menu-area li.menu-item-has-children ul").length) {
            $(".menu-area .navigation li.menu-item-has-children").append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
        }
        if ($(".mobile-menu").length) {
            var mobileMenuContent = $(".menu-area .main-menu").html();

            //Dropdown Button
            $(".mobile-menu li.menu-item-has-children .dropdown-btn").on("click", function () {
                $(this).toggleClass("open");
                $(this).prev("ul").slideToggle(500);
            });
            //Menu Toggle Btn
            $(".mobile-nav-toggler").on("click", function () {
                $("body").addClass("mobile-menu-visible");
            });

            //Menu Toggle Btn
            $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
                $("body").removeClass("mobile-menu-visible");
            });
        }

        $(".navbar-toggle").on("click", function () {
            $(".navbar-nav").addClass("mobile_menu");
        });
        $(".navbar-nav li a").on("click", function () {
            $(".navbar-collapse").removeClass("show");
        });

        $(".header-search > a").on("click", function () {
            $(".search-popup-wrap").slideToggle();
            return false;
        });

        $(".search-close").on("click", function () {
            $(".search-popup-wrap").slideUp(500);
        });
    }, []);

    return (
        <header ref={header}>
            <HeaderTopBar></HeaderTopBar>
            <div id="sticky-header" className="menu-area">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="mobile-nav-toggler h-100 ">
                                <li className="header-search">
                                    <a href="/#">
                                        <i className="flaticon-search" style={{ color: "var(--secondary-color)" }} />
                                    </a>
                                </li>
                                <i className="fas fa-bars" />
                            </div>
                            <div className="menu-wrap">
                                <nav className="menu-nav show">
                                    <Logo />
                                    <div className="navbar-wrap main-menu d-none d-xlg-flex">
                                        <ul className="navigation">
                                            {MENU_ITEMS.map((currentItem) => {
                                                return (
                                                    <li key={currentItem.name} className="">
                                                        {!currentItem.subMenu?.length ? (
                                                            <NavLink exact={true} className="navigation-links" activeClassName="active" to={currentItem.link}>
                                                                {currentItem.name}
                                                            </NavLink>
                                                        ) : (
                                                            <a href="#" className="navigation-links">{currentItem.name}</a>
                                                        )}
                                                        {currentItem.subMenu && (
                                                            <ul className="submenu">
                                                                {currentItem.subMenu.map(({ name, link }) => {
                                                                    return (
                                                                        <li key={name}>
                                                                            <NavLink to={link}>{name}</NavLink>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        )}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <div className="header-action d-none d-xlg-flex">
                                        <ul>
                                            <li className="header-search">
                                                <a href="/#">
                                                    <i className="flaticon-search" />
                                                </a>
                                            </li>

                                            <li className="header-btn">
                                                <NavLink to="/contact" className="btn">
                                                    <span>Rescue </span> <img loading="lazy" src="/img/icon/milksplash.png" alt="rescue" />
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                            <div className="mobile-menu">
                                <nav className="menu-box">
                                    <div className="close-btn">
                                        <i className="fas fa-times" />
                                    </div>
                                    <div className="p-4 logo-wrapper">
                                        <Logo />
                                    </div>
                                    <div className="menu-outer">
                                        <div className="navbar-wrap main-menu d-none d-xlg-flex">
                                            <ul className="navigation ml-0">
                                                {MENU_ITEMS.map((currentItem) => {
                                                    return (
                                                        <li key={currentItem.name} className="">
                                                            {!currentItem.subMenu?.length ? (
                                                                <NavLink onClick={closeMob} exact={true} className="navigation-links" activeClassName="active" to={currentItem.link}>
                                                                    {currentItem.name}
                                                                </NavLink>
                                                            ) : (
                                                                <a href="#" onClick={subActive} className="navigation-links">
                                                                    {currentItem.name}
                                                                </a>
                                                            )}
                                                            {currentItem.subMenu && (
                                                                <ul className="submenu-mob">
                                                                    {currentItem.subMenu.map(({ name, link }) => {
                                                                        return (
                                                                            <li key={name}>
                                                                                <NavLink onClick={closeMob} to={link}>
                                                                                    {name}
                                                                                </NavLink>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="social-links">
                                        <ul className="clearfix">
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-facebook-square" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-pinterest-p" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-instagram" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-youtube" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className="menu-backdrop" />
                        </div>
                    </div>
                </div>
                <div className="header-shape" style={{ backgroundImage: "url('/img/bg/header_shape.png')" }} />
            </div>

            <div className="search-popup-wrap" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="search-close">
                    <span>
                        <i className="fas fa-times" />
                    </span>
                </div>
                <div className="search-wrap text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <SearchTitle className="title">... Search Here ...</SearchTitle>
                                <div className="search-form">
                                    <form>
                                        <input onChange={(e) => setSearchQuery(e.target.value)} type="text" name="search" placeholder="Type keywords here" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <SearchBarResult className="row mt-30">
                            <div className="col-12">
                                {searchLoading && <Loader size={50} color="#0a303a" message="Searching..." />}
                                {searchQuery && !searchLoading && !searchResults.length && <NoSearchResults />}
                                {searchQuery && !searchLoading && !!searchResults.length && (
                                    <div className="search-result">
                                        <div className="search-title">
                                            <h4 className="text-left">
                                                Search Results - <span> {searchQuery} </span>
                                            </h4>
                                        </div>
                                        <div className="search-content bg-white p-4 ">
                                            <Swiper
                                                modules={[Grid]}
                                                direction="vertical"
                                                slidesPerView={2.2}
                                                breakpoints={{
                                                    500: { slidesPerView: 2, direction: "horizontal", grid: { rows: 2, fill: "row" } },
                                                    900: { slidesPerView: 3, direction: "horizontal", grid: { rows: 2, fill: "row" } },
                                                }}
                                                className="py-2 h-100"
                                            >
                                                {searchResults.map((result) => (
                                                    <SwiperSlide key={result["_id"]} className="items p-1">
                                                        <div >
                                                            <img loading="lazy" className="rounded" width={320} height={180} src={result["thumbnail_img"]["url"]} alt={result["title"]} />
                                                            <h6 onClick={closeSearch} className="py-2">
                                                                <Link to={`/cattle-blog/${result.slug}`}>{result["title"]} </Link>
                                                            </h6>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </SearchBarResult>
                    </div>
                </div>
            </div>
        </header>
    );
}

const HeaderTopBar = () => {
    return (
        <div className="header-top-area">
            <div className="container custom-container">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <div className="header-top-left">
                            <ul>
                                <li>24/7 Cow Rescue Hotline : 9896784225 &ensp;| </li>
                                <li>
                                    <i className="far fa-clock" />
                                    <span> Gaushala Operating Hours : 8AM to 8PM everyday</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="header-top-right">
                            <ul className="header-top-social">
                                <li className="follow">Follow :</li>
                                <li>
                                    <a href="/#">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                </li>
                                <li>
                                    <a href="/#">
                                        <i className="fab fa-twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="/#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href="/#">
                                        <i className="fab fa-linkedin-in" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const NoSearchResults = () => {
    return (
        <div className="container d-flex justify-content-center align-items-center ">
            <h2 className="message">No search results found</h2>
        </div>
    );
};

export default Header;

const SearchTitle = styled.h2`
    color: #0a303a;
    font-size: "Poppins", sans-serif;
`;

const SearchBarResult = styled.div`
    padding: 10px;

    img {
        width: 320px;
        height: 180px;
        object-fit: cover;
    }

    .search-content {
        height: 70vh;
    }
`;
// export SearchBarContainerResults = styled
