import React from "react";
import useTranslationMod from "../hooks/useTranslationMod";
import data from "./photo-gallery/photogallery.json"

const HGSAsupport = () => {
    const { t } = useTranslationMod("other");

    return (
        <section className="py-3 container px-3">
            <h2 className="text-center mb-3">HGSA Support</h2>
            <div className="we-thank my-5 text-center d-block d-sm-flex justify-content-center align-items-end">
                <h3>We Thank &nbsp; </h3>
                <div className="d-flex justify-content-center align-items-center">
                    <img loading="lazy" width={60} alt="logo" src="https://cdnbbsr.s3waas.gov.in/s3a5a61717dddc3501cfdf7a4e22d7dbaa/uploads/2020/08/2020080782.jpg" />
                    <p className="mb-0 text-left">
                        <b>हरियाणा गौ सेवा आयोग</b> <br />
                        <b className="text-dark">Haryana Gau Seva Aayog</b>
                    </p>
                </div>
            </div>
            <p>{t("hgsa.govt_organization")}</p>
            <p>{t("hgsa.registered")}</p>
            <div className="row">
                {data 
                    .find((d) => d.id === "hgsa-support")['data']
                    .map((item, index) => (
                        <div key={index} className="my-3 col-12 col-md-4 ">
                            <img loading="lazy" style={{ width: "360px", aspectRatio: "360/270" }} src={item.image} alt={item.caption.slice(0, 10)} className="img-fluid img-loading rounded" />
                            <p className="m-0">
                                <small className="text-center">{item.caption}</small>
                            </p>
                            <p className="m-0">
                                <small className="text-center">{item.hindiText}</small>
                            </p>
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default HGSAsupport;
