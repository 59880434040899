import React, { useRef } from "react";
import styled from "styled-components";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import useTranslationMod from "../hooks/useTranslationMod";

gsap.registerPlugin(ScrollTrigger);

const Donation = () => {
    const Doconatiner = useRef();
    const { t } = useTranslationMod("other");

    useGSAP(
        () => {
            const timeline = gsap.timeline({
                defaults: { duration: 1, ease: "power2.out" }, // Optional: Set global defaults
            });
            timeline.from(".cardi", {
                opacity: 0,
                x: 100,
                stagger: 0.3,
                duration: 0.5,
            });
        },
        { scope: Doconatiner }
    );

    return (
        <DonationContainer ref={Doconatiner} className="container">
            <h2 className="mb-4"> Our Patrons </h2>
            <p className="section-title text-left">{ t('patrons_heading')} </p>
            <CardWrapper>
                {cardsData.map((_, index) => (
                    <div key={index} className="cardi">
                        <img loading="lazy" src={_.image} className="card-img-top" alt="Card" />
                        <div className="card-body">
                            <h3 className="card-title">{_.title}</h3>
                            <div className="card-text">
                                <h6 className="text-white mb-0"> {_.description[0]} </h6>
                                <h4 className="text-white mt-3"> {_.description[1]} </h4>
                            </div>
                        </div>
                    </div>
                ))}
            </CardWrapper>
        </DonationContainer>
    );
};

const DonationContainer = styled.div`
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    text-align: center;
`;

const CardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;

    .cardi {
        width: 20rem;
        margin: 1rem;
        border: none;
        border-radius: 12px;
        overflow: hidden;
        cursor: pointer;
    }

    .card-img-top {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
    }

    .card-body {
        background: var(--main-color);
        padding: 1.25rem;
        text-align: center;
        height: 100%;
    }

    .card-title {
        color: white;
    }

    .card-text {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        color: white;
    }

    .btn {
        background-color: var(--main-color);
        border: none;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        transition: background-color 0.3s ease;
    }

    @media (max-width: 768px) {
        .card {
            width: 100%;
        }
    }
`;

export default Donation;

const cardsData = [
    {
        id: 1,
        title: "Sh. Satish kumar Jain",
        description: [" Rt. EX .EN ", "Chandigarh, India"],
        image: "/img/donation/P-01.jpg",
        link: "#",
    },

    {
        id: 3,
        title: "Sh. Naresh kumar Jain",
        description: ["Businessman", "Toronto Canada"],
        image: "/img/donation/P-03.jpg",
        link: "#",
    },
    {
        id: 4,
        title: "Sh Gautam Jain",
        description: ["Indsutrailist", "Vapi Gujrat"],
        image: "/img/donation/P-04.jpg",
        link: "#",
    },
    {
        id: 5,
        title: "Sh Sanjay Jain",
        description: ["Industrialist & Philanthropist", "Delhi"],
        image: "/img/donation/P-05.jpg",
        link: "#",
    },
    {
        id: 6,
        title: "Sh. Suresh Singhal",
        description: ["Businessman", "Delhi"],
        image: "/img/donation/P-06.jpg",
        link: "#",
    },
    {
        id: 7,
        title: "Dr. T R Mittal ",
        description: ["Businessman & Philanthropist", "Delhi"],
        image: "/img/donation/P-07.jpg",
        link: "#",
    },
    {
        id: 2,
        title: "Mr. Gaurav Jain",
        description: ["Entrepreneur & Businessman", "Geonix International,  New Delhi"],
        image: "/img/donation/P-02.jpg",
        link: "#",
    },
    {
        id: 8,
        title: "Ch. Jaibir Singh ",
        description: ["Late Ganga Bisan Kaliraman ", "Transporter Hisar"],
        image: "/img/donation/P-08.jpg",
        link: "#",
    },
    {
        id: 9,
        title: "Kuldeep Kala ",
        description: ["Ekta Body Maker", "Jind, Harayana"],
        image: "/img/donation/P-09.jpg",
        link: "#",
    },
    {
        id: 10,
        title: "Aditya Kumar Jain",
        description: ["Businessman", "Sainik Farms Delhi"],
        image: "/img/donation/P-10.jpg",
        link: "#",
    },
    {
        id: 11,
        title: "Ch. Shailender Goyat ",
        description: ["Ex Sarpanch ", "Kapro, Haryana"],
        image: "/img/donation/P-11.jpg",
        link: "#",
    },
    {
        id: 12,
        title: "Suresh Jain",
        description: ["Industrilist &  Philanthropist", "Karnal, Haryana"],
        image: "/img/donation/P-12.jpg",
        link: "#",
    },
    {
        id: 13,
        title: "Rajender Kumar Jain ",
        description: ["Businessman", "Delhi"],
        image: "/img/donation/P-13.jpg",
        link: "#",
    },
    {
        id: 14,
        title: "Sh. Om Prakash",
        description: ["Retired Secretary Haryana Govt.", "Kapro, Haryana"],
        image: "/img/donation/P-14.jpg",
        link: "#",
    },
    {
        id: 16,
        title: "Sh. Balwan Sharma",
        description: ["Sh. Balwan Sharma Businessman.", "Kapro - Uchana"],
        image: "/img/donation/P-15.jpg",
        link: "#",
    },
    {
        id: 17,
        title: "Sh Ramesh Aggarwal",
        description: ["Businessman & Philanthropist ", "Naya Bazar Delhi"],
        image: "/img/donation/P-16.jpg",
        link: "#",
    },
    {
        id: 18,
        title: "Ch. Dayakisan Arya Kaliraman",
        description: ['Kapro Hisar Farmer & Social Reformer',"Kapro Hisar"],
        image: "/img/donation/P-18.png",
        link: "#",
    },
    {
        id: 19,
        title: "Sh. Nand Kumar Jain",
        description: ["Educationist & Philanthropist  ", "Hisar, Haryana"],
        image: "/img/donation/P-19.jpg",
        link: "#",
    }
];
