import React from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import useBlog from "../../hooks/useBlog";
import styled from "styled-components";
import Loader from "../../common-component/Loader";
import ErrorMessage from "../../common-component/Error";

function BlogContent() {
    const { error, loading, allblogs } = useBlog();

    if (loading) {
        return (
            <div className="p-5 m-5">
                <Loader size={50} />
            </div>
        );
    }
    
    if (error) return <ErrorMessage message={error.message} />;

    return (
        <section className="standard-blog-area">
            <div className="container">
                <div className="row justify-content-center">
                    {allblogs.map((blog) => {
                        return (
                            <div className="standard-blog-item col-12 col-sm-6 col-lg-4">
                                <div className="standard-blog-thumb">
                                    <Link to={`/cattle-blog/${blog.slug}`}>
                                        <img loading="lazy" src={blog.thumbnail_img.url} width={380} height={253} alt="" />
                                    </Link>
                                </div>

                                <div className="standard-blog-content">
                                    <div className="blog-post-meta">
                                        <ul>
                                            <li>
                                                <i className="far fa-user" />
                                                <a href="/#">{blog.author_name}</a>
                                            </li>
                                            <li>
                                                <i className="far fa-bell" /> {DateTime.fromISO(blog.createdAt).toFormat("MMMM dd ,yyyy (cccc)")}
                                            </li>
                                        </ul>
                                    </div>
                                    <h4 className="title">{blog.title}</h4>

                                    <Link to={`/cattle-blog/${blog.slug}`} className="read-more">
                                        Read More <img loading="lazy" src="/img/icon/pawprint.png" alt="read more" />
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

const NoMoreBlogs = () => {
    return (
        <NoMoreBlogsContainer>
            <h3 className="heading">No More Blogs</h3>
        </NoMoreBlogsContainer>
    );
};

const NoMoreBlogsContainer = styled.div`
    padding: 20px;
    color: #555;
    width: 80%;
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    margin-inline: auto;

    .heading {
        color: var(--main-color) !important;
    }
`;

const BlogCard = () => {};
export default BlogContent;
