import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import useTranslationMod from "../hooks/useTranslationMod";
import { scrollToAnchor } from "../utils/scrollAnchor";

function Footer() {
    const { t } = useTranslationMod("home");

    const CoreDetails = [
        { text: "About", link: "/about" },
        { text: "BPSG Facilities", link: "/our_facilities" },
        { text: "Message From Chairman", link: "/message-from-chairman" },
        { text: "Message From President", link: "/message-from-president" },
        { text: "Easy Donation", link: "/contact" },
        { text: "Cattle Blog", link: "/cattle-blog" },
        { text: "Genetics", link: "/cow-genetics" },
    ];

    const PhotosAndVideos = [
        { text: "Dedicated Team", link: "/photo-gallery" },
        { text: "Visitor Book", link: "/#testimonials", hashRoute: true },
        { text: "Spread Happiness", link: "/spread-happiness" },
        { text: "Special Events", link: "/events-updates" },
        { text: "Treatment", link: "/medical-treatment" },
        { text: "Video Vault", link: "/video-vault" },
    ];

    return (
        <footer>
            <div className="footer-top-area footer-bg">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-3">
                            <div className="footer-widget">
                                <Logo width={220} />
                                <div className="footer-text">
                                    <p>{t("protective_sanctuary")}</p>
                                </div>
                                <div className="footer-contact">
                                    <div className="icon">
                                        <i className="fas fa-headphones" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <a href="tel:9896784225">9896784225</a>
                                        </h4>
                                        <span>Call Now</span>
                                    </div>
                                </div>
                                <div className="footer-social">
                                    <ul>
                                        <li>
                                            <a href="/#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="footer-widget">
                                <h4 className="fw-title">Photos And Videos</h4>
                                <div className="fw-link">
                                    <ul>
                                        {PhotosAndVideos.map((item, index) => (
                                            <li key={index}>
                                                <Link onClick={() => item.hashRoute && scrollToAnchor()} to={item.link}>{item.text}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="footer-widget">
                                <h4 className="fw-title">Core Details</h4>
                                <div className="fw-link">
                                    <ul>
                                        {CoreDetails.map((item, index) => (
                                            <li key={index}>
                                                <Link to={item.link}>{item.text}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="footer-widget">
                                <h4 className="fw-title">Designed, developed & maintained by:</h4>
                                <div className="fw-insta">
                                    <div className="geonix-logo w-75">
                                        <img loading="lazy" src="/img/logo/geonix.jpg" alt="geonix" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-shape">
                    <img loading="lazy" src="/img/other/cows-icons/cow4.png" alt="cow " />
                </div>
                <div className="footer-shape shape-two ">
                    <img loading="lazy" src="/img/other/cows-icons/cow5.png" alt="cow" />
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="copyright-text">
                                <p>Copyright © {new Date().getFullYear()} BPS Gaushala, Kapro Village, Dist Hisar, Haryana. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
