import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const i18nConfig = () =>
    i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            lng: "en",
            defaultNS: "home",
            preload: ['en', 'hi'],
            ns: ["home", "other","facilites"],
            debug: false,
            interpolation: {
                escapeValue: false,
            },
            backend: {
                loadPath: "/locales/{{lng}}/{{ns}}.json",
            },
            react: {
                useSuspense: false,
            },
        });

export default i18nConfig;
