import React from "react";
import { Link } from "react-router-dom";
import useTranslationMod from "../hooks/useTranslationMod";

function BlogsHome() {
    const { t } = useTranslationMod();
    const latestNews = [
        {
            id: 1,
            thumb: "/img/other/news-home/News1.jpg",
            tag: "Gaushala",
            tagLink: "/",
            author: "Admin",
            date: "Dec 01, 2024",
            title: "Several New ‘Matas’ at Gaushala this quarter",
            description_eng: "BPSG is delighted to welcome several new members this quarter.",
            description_hi: "बीपीएसजी इस तिमाही में कई नए सदस्यों का स्वागत करते हुए प्रसन्न है।",
        },
        {
            id: 2,
            thumb: "/img/other/news-home/News3.png",
            tag: "Event",
            tagLink: "/",
            author: "Admin",
            date: "Nov 20, 2024",
            title: "New Tractor inaugurated at BPSG",
            description_eng: "The unveiling of a new tractor is always a special event at BPSG. Many special events are held at the Gaushala year-round.",
            description_hi: "बीपीएसजी में नए ट्रैक्टर का अनावरण हमेशा एक विशेष आयोजन होता है। गौशाला में साल भर कई खास कार्यक्रम आयोजित किए जाते हैं।",
        },
        {
            id: 3,
            thumb: "/img/other/news-home/News2.jpg",
            tag: "Agriculture",
            tagLink: "/",
            author: "Admin",
            date: "Oct 15, 2024",
            title: "Napier Grass harvest excellent this year",
            description_eng: "We grow Napier Grass around the Gaushala, which is a species of perennial tropical grass, and requires less water.",
            description_hi: " हम गौशाला के आसपास नेपियर घास उगाते हैं, जो एक प्रकार की बहुवर्षीय उष्णकटिबंधीय घास है और इसे कम पानी की आवश्यकता होती है।",
        },
    ];

    return (
        <section className="blog-area pt-110 pb-60">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="section-title text-center mb-65">
                         
                            <h5 className="sub-title">Our News</h5>
                            <h2 className="title">{t("latest_news")}</h2>
                            <p>The best overall dog DNA test is Embark Breed &amp; Health Kit (view at Chewy), which provides you with a breed brwn and information Most dogs</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {latestNews.map((post) => (
                        <div className="col-lg-4 col-md-6" key={post.id}>
                            <div className="blog-post-item mb-50">
                                <div className="blog-post-thumb">
                                    <Link to={post.link}>
                                        <img loading="lazy" src={post.thumb} alt={post.title}  />
                                    </Link>
                                    <div className="blog-post-tag">
                                        <Link className="bg-white" to={post.tagLink}>
                                            <i className="flaticon-bookmark-1" />
                                            {post.tag}
                                        </Link>
                                    </div>
                                </div>
                                <div className="blog-post-content">
                                    <div className="blog-post-meta">
                                        <ul>
                                            <li>
                                                <i className="far fa-user" />
                                                <Link to="/">{post.author}</Link>
                                            </li>
                                            <li>
                                                <i className="far fa-bell" /> {post.date}
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 className="title">
                                        <Link to={post.link}>{post.title}</Link>
                                    </h3>
                                    <p className="mb-0">{(post.description_eng)}</p>
                                    <p>{(post.description_hi)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default BlogsHome;
