import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Pagination, Autoplay } from "swiper/modules";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";

function MainSlider() {
    const TOTAL_IMAGES = 12;

    return (
        <SliderContainer>
            <Swiper
                slidesPerView={1}
                speed={2000}
                grabCursor={true}
                effect={"creative"}
                creativeEffect={{
                    prev: { shadow: true, translate: ["-30%", 0, 0]  },
                    next: { translate: ["100%", 0, 0]},
                }}
                autoplay={{ delay: 1000, disableOnInteraction: false }}
                pagination={{
                    el: ".pagination-area",
                    clickable: true,
                }}
                className="slider-area"
                modules={[EffectCreative, Pagination, Autoplay]}
            >
                {Array(TOTAL_IMAGES)
                    .fill("1")
                    .map((cuurentElement, index) => {
                        return (
                            <SwiperSlide key={index}>
                                {
                                    <div className="w-100 h-100">
                                        <div className="bg-cover w-100 h-100" style={{ backgroundImage: `url('/img/slider/home/${index + 1}.jpg')` }}></div>
                                    </div>
                                }
                            </SwiperSlide>
                        );
                    })}
                <PaginationArea className="pagination-area" />
                <SplashMilk style={{ left: "3%" }}>
                    <img loading="lazy" src="/img/icon/milksplash.png" alt="splash milk" />
                </SplashMilk>
                <SplashMilk style={{ right: "3%" }}>
                    <img loading="lazy" src="/img/icon/milksplash-w.png" alt="splash milk" />
                </SplashMilk>
            </Swiper>
        </SliderContainer>
    );
}

const SplashMilk = styled.div`
    position: absolute;
    z-index: 1000;
    top: 65%;
    animation: 1s ease shake infinite;

    img {
        width: 20px;

        @media (min-width: 768px) {
            width: 40px;
        }
    }
`;

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: unset;
    display: flex;
    align-items: center;
`;

const PaginationArea = styled.div`
    position: absolute;
    left: unset !important;
    right: 22px;
    top: 50% !important;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
    height: min-content;
    width: min-content !important;

    @media (max-width: 600px) {
        right: 1px;
        height: 60%;
        flex-direction: row;
        flex-wrap: wrap;
        width: 30px !important;
    }

    .swiper-pagination-bullet {
        @media (max-width: 600px) {
            width: 6px;
            height: 6px;
        }

        width: 12px;
        height: 12px;
        background-color: white;
        opacity: 0.7;

        border-radius: 50%;
        transition: background-color 0.3s ease;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background-color: var(--main-color);
    }
`;

export default MainSlider;
